// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-event-page-js": () => import("./../../../src/templates/event-page.js" /* webpackChunkName: "component---src-templates-event-page-js" */),
  "component---src-templates-events-index-page-js": () => import("./../../../src/templates/events-index-page.js" /* webpackChunkName: "component---src-templates-events-index-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-service-page-js": () => import("./../../../src/templates/service-page.js" /* webpackChunkName: "component---src-templates-service-page-js" */)
}

